import { useState, Dispatch, SetStateAction, useEffect, useCallback } from 'react'

import { Button } from '@alien-worlds/uikit'
import { useToast } from '@chakra-ui/react'
import {
  Flex,
  Radio,
  RadioGroup,
  Text,
  Grid,
  GridItem,
  Image,
  Hide,
  useBreakpointValue,
} from '@chakra-ui/react'
import SessionKit, { Session, WalletPlugin, SessionKitArgs } from '@wharfkit/session'
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor'
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet'
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat'
import WebRenderer from '@wharfkit/web-renderer'
import axios from 'axios'
import { includes } from 'lodash'

import { SumsubModal } from '../components/SumsubModal'

const kycImage = (radioValue: string) => {
  switch (radioValue) {
    case '1':
      return './assets/images/worker.png'
    case '2':
      return './assets/images/custodian.png'
    case '3':
      return './assets/images/arbiter.png'

    default:
  }
}
const getPurpose = (radioValue: string) => {
  switch (radioValue) {
    case '1':
      return 'RECEIVER'
    case '2':
      return 'CUSTODIAN'
    case '3':
      return 'ARBITER'

    default:
      return 'ARBITER'
  }
}

export const Home = () => {
  const currentBreakpoint = useBreakpointValue({
    base: true,

    lg: false,
  })
  //const [selectedWallet, setSelectedWallet] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [sessionKit, setSessionKit] = useState<SessionKit | undefined>()
  const [, setSession]: [Session | undefined, Dispatch<SetStateAction<Session | undefined>>] =
    useState()
  const [accessToken, setAccessToken] = useState(null)
  const [, setIsVerified] = useState(false)
  const [applicantWaxAccount, setApplicantWaxAccount] = useState('')

  const [dao] = useState('all')
  const [radioValue, setRadioValue] = useState('1')
  const toast = useToast()
  const verifyKYC = async (applicantWaxAccount: string, purpose: string, dao: string) => {
    try {
      const response = await axios.post(
        'https://api.alienworlds.io/whitelist/verify',
        {
          account: applicantWaxAccount,
          purpose,
          dao,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
        }
      )

      if (response.data.token) {
        setAccessToken(response.data.token)
        setIsModalOpen(true)
      } else if (response.data.verified) {
        toast({
          title: 'Verification',
          description: applicantWaxAccount + ' is already Verified!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
        setIsVerified(true)
      } else {
        toast({
          title: 'Verification Error',
          description: 'An Error Occurred During Verification!',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        console.error('Unexpected response from KYC verification:', response.data)
      }
    } catch (error: any) {
      if (includes(error?.response?.data?.error, 'already exists')) {
        toast({
          title: 'Verification',
          description: applicantWaxAccount + ' is already Verified!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
      } else {
        toast({
          title: 'Verification Error',
          description: 'An Error Occurred During Verification!',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      }
      console.error('Error during KYC verification:', error?.response?.data)
    }
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const restoreSession = useCallback(
    async (sessionKit: SessionKit) => {
      if (sessionKit == null) {
        return
      }
      const existingSession: Session | undefined = await sessionKit.restore()

      if (existingSession != null) {
        setSession(existingSession)
      }
    },
    [setSession]
  )

  // Start new WharfKit session
  const createSession = () => {
    const sessionKitUI: WebRenderer = new WebRenderer()
    const anchorPlugin: WalletPluginAnchor = new WalletPluginAnchor()
    const wombatPlugin: WalletPluginWombat = new WalletPluginWombat()
    const waxCloudWalletPlugin: WalletPluginCloudWallet = new WalletPluginCloudWallet({
      supportedChains: [
        '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4', // WAX (Mainnet)
      ],
      url: 'https://www.mycloudwallet.com',
      autoUrl: 'https://idm-api.mycloudwallet.com/v1/accounts/auto-accept',
      loginTimeout: 300000, // 5 minutes
    })
    const walletPlugins: WalletPlugin[] = [anchorPlugin, wombatPlugin, waxCloudWalletPlugin]

    const sessionKitArgs: SessionKitArgs = {
      appName: 'Alien Worlds Verify',
      walletPlugins: walletPlugins,
      ui: sessionKitUI,
      chains: [
        {
          url: 'https://waxnode.alienworlds.io',
          id: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
        },
      ],
    }

    const sessionKit: SessionKit = new SessionKit(sessionKitArgs)

    restoreSession(sessionKit)
    setSessionKit(sessionKit)
    sessionKitUI.appendDialogElement()
  }

  useEffect(() => {
    createSession()
  }, [restoreSession])
  const login = async () => {
    const response = await sessionKit?.login()
    if (response?.session) {
      setApplicantWaxAccount(String(response.session?.actor))
      setSession(response.session)
    }
  }

  useEffect(() => {
    // If applicantWaxAccount, purpose, and dao are provided, initiate verification
    if (applicantWaxAccount.length > 0) {
      verifyKYC(applicantWaxAccount, getPurpose(radioValue), dao)
      //verifyKYC(applicantWaxAccount, 'CUSTODIAN', dao)
    }
  }, [applicantWaxAccount])

  console.log('access', accessToken, isModalOpen)
  return (
    <Flex
      flexDirection="column"
      bg="black"
      width="100%"
      justifyContent="center"
      padding="20px"
      paddingX={{ base: '20px', md: '40px:', lg: '112px' }}
      mt={{ base: '40px' }}
      gap={8}
    >
      <Flex direction="column" gap={4}>
        <Text
          color="white"
          fontFamily="Titillium Web"
          fontSize={{ base: '22px', md: '24px', lg: '28.39px' }}
          fontWeight="300"
          letterSpacing="12.77px"
        >
          _ VERIFY ME
        </Text>
        <Text fontSize="20px" color="white" fontFamily="Titillium Web" fontWeight="400px">
          Click Submit to check your verification status.
        </Text>
      </Flex>
      <Flex width="100%" justifyContent="center">
        {' '}
        <Flex width="90%">
          <Text
            color="white"
            fontFamily="Titillium Web"
            fontSize={{ base: '18px', md: '22px', xl: '24px' }}
            fontWeight="700"
          >
            Select Your Role
          </Text>
        </Flex>
      </Flex>

      <Flex
        paddingX={{ base: 0, lg: 12 }}
        paddingY={{ base: 0, lg: 4 }}
        width="100%"
        position="relative"
      >
        <Hide below="xl">
          <Image
            position="absolute"
            src="./frame.png"
            alt="frame"
            width="1490px"
            height="400px"
          ></Image>
        </Hide>

        <Grid
          mt={{ base: 0, md: 10 }}
          ml={{ base: 0, md: 8 }}
          width="100%"
          gridTemplateColumns={{ base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)' }}
          gap={8}
        >
          <GridItem order={{ base: 2, lg: 1 }}>
            <Flex
              direction="column"
              gap={8}
              ml={{ base: 0, lg: 4 }}
              alignItems="center"
              height="100%"
              justifyContent="center"
            >
              <RadioGroup
                onChange={setRadioValue}
                value={radioValue}
                defaultValue="1"
                color="white"
              >
                <Flex direction="column" gap={4}>
                  <Radio spacing={5} value="1" size="lg" colorScheme="cyan">
                    <Text
                      color={radioValue === '1' ? 'cyan' : '#777778'}
                      textShadow={radioValue === '1' ? '0px 0px 15.696px #00F3FF' : 'none'}
                      fontFamily="Titillium Web"
                      fontSize={{ base: '16px', md: '20px', lg: '24px' }}
                      fontWeight={700}
                      letterSpacing="7.68px"
                    >
                      {' '}
                      WORKER
                    </Text>
                  </Radio>
                  <Radio spacing={5} value="2" size="lg" colorScheme="cyan">
                    <Text
                      color={radioValue === '2' ? 'cyan' : '#777778'}
                      textShadow={radioValue === '2' ? '0px 0px 15.696px #00F3FF' : 'none'}
                      fontFamily="Titillium Web"
                      fontSize={{ base: '16px', md: '20px', lg: '24px' }}
                      fontWeight={700}
                      letterSpacing="7.68px"
                    >
                      {' '}
                      SYNDICATE DAO CUSTODIAN
                    </Text>
                  </Radio>
                  {/* <Radio spacing={5} value="3" size="lg" colorScheme="cyan">
                    <Text
                      color={radioValue === '3' ? 'cyan' : '#777778'}
                      textShadow={radioValue === '3' ? '0px 0px 15.696px #00F3FF' : 'none'}
                      fontFamily="Titillium Web"
                      fontSize={{ base: '16px', md: '18px', lg: '24px' }}
                      fontWeight={700}
                      letterSpacing="7.68px"
                    >
                      {' '}
                      ARBITER FOR WORKER PROPOSALS
                    </Text>
                  </Radio> */}
                </Flex>
              </RadioGroup>
            </Flex>
          </GridItem>
          <GridItem order={{ base: 1, lg: 2 }}>
            <Flex width="100%" justifyContent="center">
              <Image src={kycImage(radioValue)} width="500px" height="259px" alt=""></Image>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>

      <Flex
        mt={4}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'center', lg: 'flex-start' }}
        gap={8}
      >
        <Button
          size="lg"
          isFullWidth={currentBreakpoint}
          minWidth="260px"
          variant={'primary'}
          fontSize={20}
          onClick={async () => {
            await login()
            if (accessToken) setIsModalOpen(true)
          }}
        >
          {' '}
          Submit
        </Button>
        <Button
          size="lg"
          minWidth="260px"
          isFullWidth={currentBreakpoint}
          fontSize={20}
          variant="tertiary"
          onClick={async () => {
            // to do.
          }}
        >
          {' '}
          Cancel
        </Button>
      </Flex>
      <SumsubModal accessToken={accessToken} isModalOpen={isModalOpen} closeModal={closeModal} />
    </Flex>
  )
}
